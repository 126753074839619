<!--
 * @Description: 工程师详情
 * @Author: ChenXueLin
 * @Date: 2021-08-31 10:22:38
 * @LastEditTime: 2022-06-27 18:05:20
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <section
        class="edit-wrapper"
        v-loading="loading"
        :element-loading-background="loadingBackground"
      >
        <div class="edit-wrapper__body">
          <!-- 基本信息 -->
          <template>
            <div class="baseInfo-content-wrap">
              <div class="detailInfo-content">
                <div class="baseInfo-box">
                  <!-- <detail-table-title></detail-table-title> -->
                  <div class="edit-title">基本信息</div>
                  <el-form
                    label-width="140px"
                    style="margin-top:20px;"
                    :model="detailForm"
                  >
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="用户ID" prop="id">
                          {{ detailForm.userId }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="用户名称" prop="userName">
                          {{ detailForm.userName }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="联系电话" prop="phone">
                          {{ detailForm.phone }}
                        </el-form-item>
                      </el-col>

                      <el-col :span="8">
                        <el-form-item label="用户类型" prop="userType">
                          {{ detailForm.userType }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="所属企业" prop="userCorp">
                          {{ detailForm.userCorp }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="所属部门" prop="userFramework">
                          {{ detailForm.userFramework }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="职级" prop="userDuty">
                          {{ detailForm.userDuty }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="岗位" prop="userPost">
                          {{ detailForm.userPost }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="工号" prop="userCode">
                          {{ detailForm.userCode }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="分机号" prop="phoneExt">
                          {{ detailForm.phoneExt }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="创建人" prop="createdBy">
                          {{ detailForm.createdBy }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="创建时间" prop="createdTime">
                          {{ detailForm.createdTime }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="用户角色" prop="userRole">
                          {{ detailForm.userRole.join(",") }}
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <!-- 基本信息 end  -->
                <!-- 此功能先不做 -->
                <!-- <div class="change-type-box">
              <div class="type active">调度员</div>
              <div class="type">工程师</div>
              <div class="type">客服</div>
            </div> -->
                <div class="edit-title">工程师信息</div>
                <div>
                  <el-form
                    label-width="140px"
                    label-position="right"
                    style="margin-top:20px;"
                  >
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="名下设备">
                          <el-button type="text" @click="lookEquipment(1)">
                            名下设备
                          </el-button>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="名下任务项">
                          <el-button type="text" @click="lookEquipment(2)">
                            名下任务项
                          </el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <div class="edit-title">负责区域</div>
                <div class="table-box">
                  <el-table
                    border
                    :data="areaList"
                    highlight-current-row
                    class="elTable"
                  >
                    <el-table-column
                      show-overflow-tooltip
                      v-for="(column, index) in columnData"
                      :key="index"
                      :prop="column.fieldName"
                      :label="column.fieldLabel"
                      :min-width="column.width"
                      :fixed="column.fixed"
                      :align="column.align"
                      header-align="center"
                    >
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 认证信息  start -->
                <div class="edit-title">认证信息</div>
                <el-form
                  label-width="140px"
                  label-position="right"
                  :model="authInfo"
                  style="margin-top:15px;"
                >
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="身份证号码">
                        <template>
                          <span style="margin-right:30px;">
                            {{ idCard }}
                          </span>
                          <el-button type="text" @click="seeMore('idCard')"
                            >查看完整</el-button
                          >
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="开户银行">
                        {{ authInfo.bank }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="银行卡号">
                        <template>
                          <span style="margin-right:30px;">
                            {{ bankCard }}
                          </span>
                          <el-button type="text" @click="seeMore('bankCard')"
                            >查看完整</el-button
                          >
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="开户支行">
                        {{ authInfo.branch }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>

                <!-- 认证信息  end -->

                <!-- 业务数据 start  先不做-->
                <!-- <div class="edit-title">业务数据</div>
            <div class="score-data">
              <div class="score-box">
                <div class="score-til">累计</div>
                <el-row>
                  <el-col :span="18">
                    <el-row>
                      <el-col :span="8">
                        <div class="score">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="score">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="score score-last">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="score">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="score">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="score score-last">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6">
                    <div class="star-content">
                      <div class="score-number">6.8</div>
                      <div>
                        <el-rate v-model="value"> </el-rate>
                      </div>
                      <div>评分</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="score-box score-box2">
                <div class="date-picker">
                  <el-date-picker
                    v-model="dateVal"
                    type="month"
                    popper-class="special"
                    format="yyyy年MM月"
                  >
                  </el-date-picker>
                  <i class="e6-icon-arrow-b_line"></i>
                </div>
                <el-row>
                  <el-col :span="6">
                    <div class="score">
                      <div>321</div>
                      <div class="e6-icon-order-completed score-title">
                        累计完成任务
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="score">
                      <div>321</div>
                      <div class="e6-icon-order-completed score-title">
                        累计完成任务
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="score score-last">
                      <div>321</div>
                      <div class="e6-icon-order-completed score-title">
                        累计完成任务
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div> -->
                <!-- 业务数据 end -->
              </div>
            </div>
          </template>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
// import detailTitleContent from "@/components/detailTitleContent";
import { getBasic, engineerArea, getUserAuth } from "@/api";
import { printError } from "@/utils/util";

export default {
  name: "engineerDetail",
  components: {
    // detailTitleContent
  },
  data() {
    return {
      loading: false,
      userId: "", //用户id
      detailForm: {
        id: "", //用户id
        userName: "", //用户名称
        phone: "", //手机号
        userRole: [], //用户角色
        userType: "", //用户类型
        userCorp: "", //所属企业
        userFramework: "", //所属部门
        userDuty: "", //职级
        userPost: "", //岗位
        userCode: "", //工号
        phoneExt: "", //分机号
        createdBy: "", //创建人
        createdTime: "" //创建时间
      },
      addForm: {
        userCode: "", //工号
        userName: "", //用户名称
        phone: "", //手机号
        phoneExt: "", //分机号
        userRoleId: [], //用户角色
        userDutyId: "", //职级
        userPostId: "", //岗位
        userFrameworkId: [] //所属部门
      },
      columnData: [
        {
          fieldName: "areaTypeName",
          display: true,
          fieldLabel: "区域类型",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "area",
          display: true,
          fieldLabel: "区域",
          width: 500,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //负责区域表头
      authInfo: {
        idCard: "", //身份证号
        bankCard: "", //银行卡号
        bank: "", //开户银行
        branch: "" //开户支行
      }, //认证信息
      equipNum: 13, //名下设备数量
      taskNum: 13, //名下任务项数量
      idCard: "", //完整的身份证号
      bankCard: "", //完整的银行卡号
      areaList: [] //负责区域列表
    };
  },
  props: [],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.userId = hasParamsRoute.params.userId;
      if (this.userId) {
        this.initData();
        this.getBasic();
      }
    },
    async initData() {
      let promiseList = [getUserAuth({ id: this.userId })];
      let [authRes] = await Promise.all(promiseList);
      this.authInfo = _.cloneDeep(authRes.data);
      this.editAuthForm = _.cloneDeep(authRes.data);
      this.idCard = this.handleNum(_.cloneDeep(this.authInfo.idCard)); //完整的身份证号
      this.bankCard = this.handleNum(_.cloneDeep(this.authInfo.bankCard)); //完整的身份证号
    },
    //获取用户基本信息
    async getBasic() {
      try {
        this.loading = true;
        let res = await getBasic({ id: this.userId });
        this.detailForm = _.cloneDeep(res.data);
        this.addForm = _.cloneDeep(res.data);
        this.engineerArea();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取工程师负责区域
    async engineerArea() {
      try {
        this.loading = true;
        let res = await engineerArea({
          employeeId: this.detailForm.employeeId
        });
        let areaList = res.data;
        areaList.map(item => {
          if (item.areaAndRoleList.length) {
            item.areaAndRoleList.map(each => {
              if (each.areaRoleType == 1) {
                each.newAreaName =
                  each.areaName + "(" + each.areaRoleTypeName + ")";
              } else {
                each.newAreaName = each.areaName;
              }
            });
          }
          item.area = item.areaAndRoleList
            .map(each => {
              return each.newAreaName;
            })
            .join(",");
        });
        this.areaList = areaList;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理卡号
    handleNum(value) {
      if (value && value.length > 5) {
        let reg = /^(\d{3})(\d*)(\d{4})$/;
        let str = value.replace(reg, (a, b, c, d) => {
          return b + c.replace(/\d/g, "*") + d;
        });
        return str;
      } else {
        return value;
      }
    },
    //查看完整
    seeMore(type) {
      if (type == "idCard") {
        if (this.idCard.indexOf("*") > -1) {
          this.idCard = this.authInfo.idCard;
        } else {
          this.idCard = this.handleNum(_.cloneDeep(this.authInfo.idCard));
        }
      } else {
        if (this.bankCard.indexOf("*") > -1) {
          this.bankCard = this.authInfo.bankCard;
        } else {
          this.bankCard = this.handleNum(_.cloneDeep(this.authInfo.bankCard));
        }
      }
    },
    /***   编辑基本信息 start *****/
    //关闭编辑弹框
    handleClose() {
      this.addUserVisible = false;
    },

    /******编辑认证信息********/
    //点击编辑认证信息
    handleEditBank() {
      this.editAuthDialog = true;
      this.editAuthForm = _.cloneDeep(this.authInfo);
    },
    //查看名下设备/名下任务项
    lookEquipment(type) {
      if (type == 1) {
        this.routerPush({
          name: "engineerManage/ownEquipment",
          params: {
            refresh: true,
            userCode: this.detailForm.employeeNo
          }
        });
      } else {
        this.routerPush({
          name: "engineerManage/ownTask",
          params: {
            refresh: true,
            engineerrpid: this.detailForm.employeeId
          }
        });
      }
    },
    //点击编辑负责区域
    toAddressManage() {
      this.routerPush({
        name: "operationsCenter/areaManage",
        params: {
          refresh: false
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
